import React from "react";


const Router = () => {
    return (
        <>
            <section className="miaru-coming-soon">
                    <img className="logo" src="assets/logo.jpg" />
                    <h1>Coming Soon!</h1>
            </section>
        </>
    );
};

export default Router;