import React from "react";
import ReactHtmlParser from "react-html-parser";
import { AccordionBody, AccordionHeader, AccordionItem, UncontrolledAccordion } from 'reactstrap';
import { DefaultStrings as ds } from "src/common/DefaultTranslations";
import {ErrorBoundary} from "src/common/ErrorBoundary";
import { translate } from "src/common/I18N";
import { Links } from "src/common/Links";



const FAQPage = () => {

    return (
        <ErrorBoundary type={"Miaru-FAQ"}>
          <div className="miaru-faq">
              <div className="">
              <div className="container">
                <div className="row py-5">
                  <div className="col text-lg-start">
                    <h1 className="fw-bold lh-1 mb-3">
                      {translate(ds.faqPageTitle.stringId, ds.faqPageTitle.defaultString)}
                    </h1>
                  </div>
                </div>
                <div className="row pb-2">
                  <div className="col text-lg-start">
                    <h3 className="lh-1 mb-3">
                         {translate(ds.faqPageGeneralHeading.stringId, ds.faqPageGeneralHeading.defaultString)}
                    </h3>
                    <div className="container">
                        <div className="row pb-3 align-items-center">
                            <div className="col-lg-6 col-md-12 mb-2">
                                <UncontrolledAccordion>
                                    <AccordionItem>
                                        <AccordionHeader targetId="1">
                                            {translate(ds.faqPageGeneralQ1.stringId, ds.faqPageGeneralQ1.defaultString)}
                                        </AccordionHeader>
                                        <AccordionBody accordionId="1">
                                            {translate(ds.faqPageGeneralA1.stringId, ds.faqPageGeneralA1.defaultString)}
                                        </AccordionBody>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionHeader targetId="2">
                                            {translate(ds.faqPageGeneralQ2.stringId, ds.faqPageGeneralQ2.defaultString)}
                                        </AccordionHeader>
                                        <AccordionBody accordionId="2">
                                            {ReactHtmlParser(translate(ds.faqPageGeneralA2.stringId, ds.faqPageGeneralA2.defaultString))}
                                        </AccordionBody>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionHeader targetId="3">
                                            {translate(ds.faqPageGeneralQ3.stringId, ds.faqPageGeneralQ3.defaultString)}
                                        </AccordionHeader>
                                        <AccordionBody accordionId="3">
                                            {ReactHtmlParser(translate(ds.faqPageGeneralA3.stringId, ds.faqPageGeneralA3.defaultString))}
                                        </AccordionBody>
                                    </AccordionItem>
                                </UncontrolledAccordion>
                            </div>
                            <div className="col-lg-6 col-md-12 mb-2" />
                        </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row pb-2">
                  <div className="col text-lg-start">
                    <h3 className="lh-1 mb-3">
                        {translate(ds.faqPageLifetimeHeading.stringId, ds.faqPageLifetimeHeading.defaultString)}
                    </h3>
                    <div className="container">
                        <div className="row pb-3 align-items-center">
                            <div className="col-lg-6 col-md-12 mb-2">
                                <UncontrolledAccordion>
                                    <AccordionItem>
                                        <AccordionHeader targetId="1">
                                        {translate(ds.faqPageLifetimeQ1.stringId, ds.faqPageLifetimeQ1.defaultString)}
                                        </AccordionHeader>
                                        <AccordionBody accordionId="1">
                                        {translate(ds.faqPageLifetimeA1.stringId, ds.faqPageLifetimeA1.defaultString)}
                                        </AccordionBody>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionHeader targetId="2">
                                        {translate(ds.faqPageLifetimeQ2.stringId, ds.faqPageLifetimeQ2.defaultString)}
                                        </AccordionHeader>
                                        <AccordionBody accordionId="2">
                                        {translate(ds.faqPageLifetimeA2.stringId, ds.faqPageLifetimeA2.defaultString)}
                                        </AccordionBody>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionHeader targetId="3">
                                        {translate(ds.faqPageLifetimeQ3.stringId, ds.faqPageLifetimeQ3.defaultString)}
                                        </AccordionHeader>
                                        <AccordionBody accordionId="3">
                                        {translate(ds.faqPageLifetimeA3.stringId, ds.faqPageLifetimeA3.defaultString)}
                                        </AccordionBody>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionHeader targetId="4">
                                        {translate(ds.faqPageLifetimeQ4.stringId, ds.faqPageLifetimeQ4.defaultString)}
                                        </AccordionHeader>
                                        <AccordionBody accordionId="4">
                                        {translate(ds.faqPageLifetimeA4.stringId, ds.faqPageLifetimeA4.defaultString)}
                                        </AccordionBody>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionHeader targetId="5">
                                        {translate(ds.faqPageLifetimeQ5.stringId, ds.faqPageLifetimeQ5.defaultString)}
                                        </AccordionHeader>
                                        <AccordionBody accordionId="5">
                                        {translate(ds.faqPageLifetimeA5.stringId, ds.faqPageLifetimeA5.defaultString)}
                                        </AccordionBody>
                                    </AccordionItem>
                                </UncontrolledAccordion>
                            </div>
                            <div className="col-lg-6 col-md-12 mb-2">
                                <UncontrolledAccordion>
                                    <AccordionItem>
                                        <AccordionHeader targetId="6">
                                        {translate(ds.faqPageLifetimeQ6.stringId, ds.faqPageLifetimeQ6.defaultString)}
                                        </AccordionHeader>
                                        <AccordionBody accordionId="6">
                                        {translate(ds.faqPageLifetimeA6.stringId, ds.faqPageLifetimeA6.defaultString)}
                                        </AccordionBody>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionHeader targetId="7">
                                        {translate(ds.faqPageLifetimeQ7.stringId, ds.faqPageLifetimeQ7.defaultString)}
                                        </AccordionHeader>
                                        <AccordionBody accordionId="7">
                                        {translate(ds.faqPageLifetimeA7.stringId, ds.faqPageLifetimeA7.defaultString)}
                                        <ul>
                                            <li>
                                            {translate(ds.faqPageLifetimeA7B1.stringId, ds.faqPageLifetimeA7B1.defaultString)}
                                            </li>
                                            <li>
                                            {translate(ds.faqPageLifetimeA7B2.stringId, ds.faqPageLifetimeA7B2.defaultString)}
                                            </li>
                                            <li>
                                            {translate(ds.faqPageLifetimeA7B3.stringId, ds.faqPageLifetimeA7B3.defaultString)}
                                            </li>
                                        </ul>
                                        </AccordionBody>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionHeader targetId="8">
                                        {translate(ds.faqPageLifetimeQ8.stringId, ds.faqPageLifetimeQ8.defaultString)}
                                        </AccordionHeader>
                                        <AccordionBody accordionId="8">
                                        {ReactHtmlParser(translate(ds.faqPageLifetimeA8.stringId, ds.faqPageLifetimeA8.defaultString))}
                                        </AccordionBody>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionHeader targetId="9">
                                        {translate(ds.faqPageLifetimeQ9.stringId, ds.faqPageLifetimeQ9.defaultString)}
                                        </AccordionHeader>
                                        <AccordionBody accordionId="9">
                                        {translate(ds.faqPageLifetimeA9.stringId, ds.faqPageLifetimeA9.defaultString)}
                                        </AccordionBody>
                                    </AccordionItem>
                                </UncontrolledAccordion>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row pb-2">
                  <div className="col text-lg-start">
                    <h3 className="lh-1 mb-3">
                        {translate(ds.faqPageMonthlyHeading.stringId, ds.faqPageMonthlyHeading.defaultString)}
                    </h3>
                    <div className="container">
                        <div className="row pb-3 align-items-center">
                            <div className="col-lg-6 col-md-12 mb-2">
                                <UncontrolledAccordion>
                                    <AccordionItem>
                                        <AccordionHeader targetId="1">
                                        {translate(ds.faqPageMonthlyQ1.stringId, ds.faqPageMonthlyQ1.defaultString)}
                                        </AccordionHeader>
                                        <AccordionBody accordionId="1">
                                        {translate(ds.faqPageMonthlyA1.stringId, ds.faqPageMonthlyA1.defaultString)}
                                        </AccordionBody>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionHeader targetId="2">
                                        {translate(ds.faqPageMonthlyQ2.stringId, ds.faqPageMonthlyQ2.defaultString)}
                                        </AccordionHeader>
                                        <AccordionBody accordionId="2">
                                        {translate(ds.faqPageMonthlyA2.stringId, ds.faqPageMonthlyA2.defaultString)}
                                        </AccordionBody>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionHeader targetId="3">
                                        {translate(ds.faqPageMonthlyQ3.stringId, ds.faqPageMonthlyQ3.defaultString)}
                                        </AccordionHeader>
                                        <AccordionBody accordionId="3">
                                        {translate(ds.faqPageMonthlyA3.stringId, ds.faqPageMonthlyA3.defaultString)}
                                        <ul>
                                            <li>
                                            {translate(ds.faqPageMonthlyA3B1.stringId, ds.faqPageMonthlyA3B1.defaultString)}
                                            </li>
                                            <li>
                                            {translate(ds.faqPageMonthlyA3B2.stringId, ds.faqPageMonthlyA3B2.defaultString)}
                                            </li>
                                            <li>
                                            {translate(ds.faqPageMonthlyA3B3.stringId, ds.faqPageMonthlyA3B3.defaultString)}
                                            </li>
                                        </ul>
                                        </AccordionBody>
                                    </AccordionItem>
                                </UncontrolledAccordion>
                            </div>
                            <div className="col-lg-6 col-md-12 mb-2" />
                        </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row pb-2">
                  <div className="col text-lg-start">
                    <h3 className="lh-1 mb-3">
                        {translate(ds.faqPageYearlyHeading.stringId, ds.faqPageYearlyHeading.defaultString)}
                    </h3>
                    <div className="container">
                        <div className="row pb-3 align-items-center">
                            <div className="col-lg-6 col-md-12 mb-2">
                                <UncontrolledAccordion>
                                    <AccordionItem>
                                        <AccordionHeader targetId="1">
                                        {translate(ds.faqPageYearlyQ1.stringId, ds.faqPageYearlyQ1.defaultString)}
                                        </AccordionHeader>
                                        <AccordionBody accordionId="1">
                                        {translate(ds.faqPageYearlyA1.stringId, ds.faqPageYearlyA1.defaultString)}
                                        </AccordionBody>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionHeader targetId="2">
                                        {translate(ds.faqPageYearlyQ2.stringId, ds.faqPageYearlyQ2.defaultString)}
                                        </AccordionHeader>
                                        <AccordionBody accordionId="2">
                                        {translate(ds.faqPageYearlyA2.stringId, ds.faqPageYearlyA2.defaultString)}
                                        </AccordionBody>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionHeader targetId="3">
                                        {translate(ds.faqPageYearlyQ3.stringId, ds.faqPageYearlyQ3.defaultString)}
                                        </AccordionHeader>
                                        <AccordionBody accordionId="3">
                                        {translate(ds.faqPageYearlyA3.stringId, ds.faqPageYearlyA3.defaultString)}
                                        <ul>
                                            <li>
                                            {translate(ds.faqPageYearlyA3B1.stringId, ds.faqPageYearlyA3B1.defaultString)}
                                            </li>
                                            <li>
                                            {translate(ds.faqPageYearlyA3B2.stringId, ds.faqPageYearlyA3B2.defaultString)}
                                            </li>
                                            <li>
                                            {translate(ds.faqPageYearlyA3B3.stringId, ds.faqPageYearlyA3B3.defaultString)}
                                            </li>
                                        </ul>
                                        </AccordionBody>
                                    </AccordionItem>
                                </UncontrolledAccordion>
                            </div>
                            <div className="col-lg-6 col-md-12 mb-2" />
                        </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row pb-2">
                  <div className="col text-lg-start">
                    <h3 className="lh-1 mb-3">
                        {translate(ds.faqPageNFTHeading.stringId, ds.faqPageNFTHeading.defaultString)}
                    </h3>
                    <div className="container">
                        <div className="row pb-3 align-items-center">
                            <div className="col-lg-6 col-md-12 mb-2">
                                <UncontrolledAccordion>
                                    <AccordionItem>
                                        <AccordionHeader targetId="1">
                                        {translate(ds.faqPageNFTQ1.stringId, ds.faqPageNFTQ1.defaultString)}
                                        </AccordionHeader>
                                        <AccordionBody accordionId="1">
                                        {translate(ds.faqPageNFTA1.stringId, ds.faqPageNFTA1.defaultString)}
                                        </AccordionBody>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionHeader targetId="2">
                                        {translate(ds.faqPageNFTQ2.stringId, ds.faqPageNFTQ2.defaultString)}
                                        </AccordionHeader>
                                        <AccordionBody accordionId="2">
                                        {translate(ds.faqPageNFTA2.stringId, ds.faqPageNFTA2.defaultString)}
                                        </AccordionBody>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionHeader targetId="3">
                                        {translate(ds.faqPageNFTQ3.stringId, ds.faqPageNFTQ3.defaultString)}
                                        </AccordionHeader>
                                        <AccordionBody accordionId="3">
                                        {translate(ds.faqPageNFTA3.stringId, ds.faqPageNFTA3.defaultString)}
                                        </AccordionBody>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionHeader targetId="4">
                                        {translate(ds.faqPageNFTQ4.stringId, ds.faqPageNFTQ4.defaultString)}
                                        </AccordionHeader>
                                        <AccordionBody accordionId="4">
                                        {translate(ds.faqPageNFTA4.stringId, ds.faqPageNFTA4.defaultString)}
                                        <ul>
                                            <li>
                                            {translate(ds.faqPageNFTA4B0.stringId, ds.faqPageNFTA4B0.defaultString)}
                                            </li>
                                            <li>
                                            {translate(ds.faqPageNFTA4B1.stringId, ds.faqPageNFTA4B1.defaultString)}
                                            </li>
                                            <li>
                                            {translate(ds.faqPageNFTA4B2.stringId, ds.faqPageNFTA4B2.defaultString)}
                                            </li>
                                            <li>
                                            {translate(ds.faqPageNFTA4B3.stringId, ds.faqPageNFTA4B3.defaultString)}
                                            </li>
                                            <li>
                                            {translate(ds.faqPageNFTA4B4.stringId, ds.faqPageNFTA4B4.defaultString)}
                                            </li>
                                            <li>
                                            {translate(ds.faqPageNFTA4B5.stringId, ds.faqPageNFTA4B5.defaultString)}
                                            </li>
                                            <li>
                                            {translate(ds.faqPageNFTA4B6.stringId, ds.faqPageNFTA4B6.defaultString)}
                                            </li>
                                            <li>
                                            {translate(ds.faqPageNFTA4B7.stringId, ds.faqPageNFTA4B7.defaultString)}
                                            </li>
                                        </ul>
                                        </AccordionBody>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionHeader targetId="5">
                                        {translate(ds.faqPageNFTQ5.stringId, ds.faqPageNFTQ5.defaultString)}
                                        </AccordionHeader>
                                        <AccordionBody accordionId="5">
                                        {translate(ds.faqPageNFTA5.stringId, ds.faqPageNFTA5.defaultString)}
                                        </AccordionBody>
                                    </AccordionItem>                                    
                                </UncontrolledAccordion>
                            </div>
                            <div className="col-lg-6 col-md-12 mb-2" >
                                <UncontrolledAccordion>
                                    <AccordionItem>
                                        <AccordionHeader targetId="1">
                                        {translate(ds.faqPageNFTQ6.stringId, ds.faqPageNFTQ6.defaultString)}
                                        </AccordionHeader>
                                        <AccordionBody accordionId="1">
                                        {translate(ds.faqPageNFTA6.stringId, ds.faqPageNFTA6.defaultString)}
                                        </AccordionBody>
                                    </AccordionItem> 
                                    <AccordionItem>
                                        <AccordionHeader targetId="2">
                                        {translate(ds.faqPageNFTQ7.stringId, ds.faqPageNFTQ7.defaultString)}
                                        </AccordionHeader>
                                        <AccordionBody accordionId="2">
                                        {translate(ds.faqPageNFTA7.stringId, ds.faqPageNFTA7.defaultString)}
                                        </AccordionBody>
                                    </AccordionItem> 
                                    <AccordionItem>
                                        <AccordionHeader targetId="3">
                                        {translate(ds.faqPageNFTQ8.stringId, ds.faqPageNFTQ8.defaultString)}
                                        </AccordionHeader>
                                        <AccordionBody accordionId="3">
                                        {translate(ds.faqPageNFTA8.stringId, ds.faqPageNFTA8.defaultString)}
                                        </AccordionBody>
                                    </AccordionItem> 
                                    <AccordionItem>
                                        <AccordionHeader targetId="5">
                                        {translate(ds.faqPageNFTQ10.stringId, ds.faqPageNFTQ10.defaultString)}
                                        </AccordionHeader>
                                        <AccordionBody accordionId="5">
                                        {translate(ds.faqPageNFTA10.stringId, ds.faqPageNFTA10.defaultString)}
                                        </AccordionBody>
                                    </AccordionItem>                                    
                                </UncontrolledAccordion>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row pb-2">
                  <div className="col text-lg-start">
                    <h3 className="lh-1 mb-3">
                        {translate(ds.faqPageMerchHeading.stringId, ds.faqPageMerchHeading.defaultString)}
                    </h3>
                    <div className="container">
                        <div className="row pb-3 align-items-center">
                            <div className="col-lg-6 col-md-12 mb-2">
                                <UncontrolledAccordion>
                                    <AccordionItem>
                                        <AccordionHeader targetId="1">
                                        {translate(ds.faqPageMerchQ1.stringId, ds.faqPageMerchQ1.defaultString)}
                                        </AccordionHeader>
                                        <AccordionBody accordionId="1">
                                        {translate(ds.faqPageMerchA1.stringId, ds.faqPageMerchA1.defaultString)}
                                        </AccordionBody>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionHeader targetId="2">
                                        {translate(ds.faqPageMerchQ2.stringId, ds.faqPageMerchQ2.defaultString)}
                                        </AccordionHeader>
                                        <AccordionBody accordionId="2">
                                        {translate(ds.faqPageMerchA2.stringId, ds.faqPageMerchA2.defaultString)}
                                        </AccordionBody>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionHeader targetId="3">
                                        {translate(ds.faqPageMerchQ3.stringId, ds.faqPageMerchQ3.defaultString)}
                                        </AccordionHeader>
                                        <AccordionBody accordionId="3">
                                        {translate(ds.faqPageMerchA3.stringId, ds.faqPageMerchA3.defaultString)}
                                        </AccordionBody>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionHeader targetId="4">
                                        {translate(ds.faqPageMerchQ4.stringId, ds.faqPageMerchQ4.defaultString)}
                                        </AccordionHeader>
                                        <AccordionBody accordionId="4">
                                        {translate(ds.faqPageMerchA4.stringId, ds.faqPageMerchA4.defaultString)}
                                        </AccordionBody>
                                    </AccordionItem>
                                </UncontrolledAccordion>
                            </div>
                            <div className="col-lg-6 col-md-12 mb-2" />
                        </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row pb-2">
                  <div className="col text-lg-start">
                    <h3 className="lh-1 mb-3">
                        {translate(ds.faqPageMobileHeading.stringId, ds.faqPageMobileHeading.defaultString)}
                    </h3>
                    <div className="container">
                        <div className="row pb-3 align-items-center">
                            <div className="col-lg-6 col-md-12 mb-2">
                                <UncontrolledAccordion>
                                    <AccordionItem>
                                        <AccordionHeader targetId="1">
                                        {translate(ds.faqPageMobileQ1.stringId, ds.faqPageMobileQ1.defaultString)}
                                        </AccordionHeader>
                                        <AccordionBody accordionId="1">
                                        {translate(ds.faqPageMobileA1.stringId, ds.faqPageMobileA1.defaultString)}
                                        </AccordionBody>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionHeader targetId="2">
                                        {translate(ds.faqPageMobileQ2.stringId, ds.faqPageMobileQ2.defaultString)}
                                        </AccordionHeader>
                                        <AccordionBody accordionId="2">
                                        {translate(ds.faqPageMobileA2.stringId, ds.faqPageMobileA2.defaultString)}
                                        </AccordionBody>
                                    </AccordionItem>
                                    <AccordionItem>
                                        <AccordionHeader targetId="3">
                                        {translate(ds.faqPageMobileQ3.stringId, ds.faqPageMobileQ3.defaultString)}
                                        </AccordionHeader>
                                        <AccordionBody accordionId="3">
                                        {translate(ds.faqPageMobileA3.stringId, ds.faqPageMobileA3.defaultString)}
                                        </AccordionBody>
                                    </AccordionItem>
                                </UncontrolledAccordion>
                            </div>
                            <div className="col-lg-6 col-md-12 mb-2" />
                        </div>
                    </div>
                  </div>
                </div>

                <div className="row py-5 justify-content-center">
                  <div className="col-10 text-lg-start">
                    <div className="p-5 text-center miaru-bg rounded-3">
                    
                        <h1 className="text-white">{translate(ds.faqPageIssue.stringId, ds.faqPageIssue.defaultString)}</h1>
                        <p className="col-lg-8 mx-auto fs-5 text-white">
                            {translate(ds.faqPageIssueSubText.stringId, ds.faqPageIssueSubText.defaultString)}
                        </p>
                        <div className="d-inline-flex gap-2 mb-5">
                            <a href={Links.email}>
                                <button className="d-inline-flex align-items-center btn btn-primary btn-lg px-4" type="button">
                                    {translate(ds.faqPageIssueButton.stringId, ds.faqPageIssueButton.defaultString)}
                                </button>
                            </a>
                        </div>
                    </div>
                  </div>
                </div>
    
              </div>
              </div>

          </div>
        </ErrorBoundary>
    );
};

export default FAQPage;
