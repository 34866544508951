import React from "react";
import { DefaultStrings as ds } from "src/common/DefaultTranslations";
import {ErrorBoundary} from "src/common/ErrorBoundary";
import { translate } from "src/common/I18N";
import { Links } from "src/common/Links";



const PressPage = () => {

    return (
        <ErrorBoundary type={"Miaru-Press"}>
          <div className="miaru-press">
              <div className="">
              <div className="container">
                <div className="row py-5">
                  <div className="col text-lg-start">
                    <h1 className="fw-bold lh-1 mb-3">
                      {translate(ds.pressPageTitle.stringId, ds.pressPageTitle.defaultString)}
                    </h1>
                  </div>
                </div>
                <div className="row pb-2">
                  <div className="col text-lg-start">
                    <div className="container">
                        <div className="row align-items-center">
                            <h6 className="text-info">February 10th, 2025</h6>
                            <a href="https://miaru.medium.com/miaru-x-base-0acfa915055a" target="_blank">
                                <h3>Miaru x Base</h3>
                            </a>
                        </div>
                        <div className="row align-items-center">
                            <h6 className="text-info">January 24th, 2025</h6>
                            <a href="https://miaru.medium.com/miaru-luffy-transforming-education-withweb3-7dda4987e934" target="_blank">
                                <h3>Miaru & Luffy: Transforming Education with Web3</h3>
                            </a>
                        </div>
                        <div className="row align-items-center">
                            <h6 className="text-info">January 15th, 2025</h6>
                            <a href="https://miaru.medium.com/luffy-nft-crowdfunding-the-future-of-education-b294019fcfa7" target="_blank">
                                <h3>Luffy NFT: Crowdfunding the Future of Education</h3>
                            </a>
                        </div>
                        <div className="row align-items-center">
                            <h6 className="text-info">January 11th, 2025</h6>
                            <a href="https://miaru.medium.com/re-introducing-luffy-tales-of-a-mascot-d859ee95d641" target="_blank">
                                <h3>Re-Introducing Luffy, Tales of a Mascot</h3>
                            </a>
                        </div>
                        <div className="row align-items-center">
                            <h6 className="text-info">January 6th, 2025</h6>
                            <a href="https://miaru.medium.com/miaru-revolutionizing-education-34a205b5d4c8" target="_blank">
                                <h3>MIARU: REVOLUTIONIZING EDUCATION</h3>
                            </a>
                        </div>
                        <div className="row align-items-center">
                            <h6 className="text-info">June 26th, 2023</h6>
                            <a href="https://miaru.medium.com/miarus-mascot-luffy-b02175cd058b" target="_blank">
                                <h3>Miaru’s Mascot Luffy</h3>
                            </a>
                        </div>
                        <hr />
                        
                    </div>
                  </div>
                </div>
                <div className="row py-5 justify-content-center">
                  <div className="col-10 text-lg-start">
                    <div className="p-5 text-center miaru-bg rounded-3">
                    
                        <h1 className="text-white">{translate(ds.pressPagePressKitHeading.stringId, ds.pressPagePressKitHeading.defaultString)}</h1>
                        <p className="col-lg-8 mx-auto fs-5 text-white">
                            {translate(ds.pressPagePressKitSubHeading.stringId, ds.pressPagePressKitSubHeading.defaultString)}
                        </p>
                        <div className="d-inline-flex gap-2 mb-5">
                            <a href={Links.pressKit} target="_blank">
                                <button className="d-inline-flex align-items-center btn btn-primary btn-lg px-4" type="button">
                                    {translate(ds.pressPagePressKitLinkText.stringId, ds.pressPagePressKitLinkText.defaultString)}
                                </button>
                            </a>
                        </div>
                    </div>
                  </div>
                </div>
    
              </div>
              </div>

          </div>
        </ErrorBoundary>
    );
};

export default PressPage;
