import React from "react";
import { DefaultStrings as ds } from "src/common/DefaultTranslations";
import {ErrorBoundary} from "src/common/ErrorBoundary";
import { translate } from "src/common/I18N";
import { Links } from "src/common/Links";



const LuffyNftPage = () => {

    return (
        <ErrorBoundary type={"Miaru-Luffy-NFT"}>
          <div className="miaru-luffy-nft-page">
              <div className="miaru-bg">
                <div className="container">
                  <div className="row py-5">
                      <div className="col text-lg-start">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-6 col-md-12 mb-2 text-info">
                                    <h1>
                                      <img title={translate(ds.luffyNftPageMiaruImg.stringId, ds.luffyNftPageMiaruImg.defaultString) as string} className="main-logo" src="assets/logo.png"/>
                                    </h1>
                                    <h1 className="lh-1 mb-3">
                                      {translate(ds.luffyNftPageSec1Title.stringId, ds.luffyNftPageSec1Title.defaultString)}
                                    </h1>
                                    <h4 className="">
                                      {translate(ds.luffyNftPageSec1SubHeading.stringId, ds.luffyNftPageSec1SubHeading.defaultString)}
                                    </h4>
                                    <p>
                                    {translate(ds.luffyNftPageSec1Text.stringId, ds.luffyNftPageSec1Text.defaultString)}
                                    </p>
                                    <a href={Links.nftSale} target="_blank" className="btn btn-primary btn-lg d-block mx-auto position-relative app-store-btn">
                                      {translate(ds.luffyNftPageSec1linkText.stringId, ds.luffyNftPageSec1linkText.defaultString)}
                                    </a>
                                </div>
                              <div className="col-lg-6 col-md-12 mb-2">
                                  <img title={translate(ds.luffyNftPageNFTImg.stringId, ds.luffyNftPageNFTImg.defaultString) as string} className="team-img img-fluid mx-auto d-block" src="assets/img/mascot/nft_collage_2.png"/>
                              </div>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="">
                    <div className="row g-0 align-items-center">
                        <div className="col">
                            <img title={translate(ds.luffyNftPageNFTImg.stringId, ds.luffyNftPageNFTImg.defaultString) as string} className="team-img img-fluid mx-auto d-block" src="assets/img/nft/pose_1.png"/>
                        </div>
                        <div className="col">
                            <img title={translate(ds.luffyNftPageNFTImg.stringId, ds.luffyNftPageNFTImg.defaultString) as string} className="team-img img-fluid mx-auto d-block" src="assets/img/nft/pose_2.png"/>
                        </div>
                        <div className="col">
                            <img title={translate(ds.luffyNftPageNFTImg.stringId, ds.luffyNftPageNFTImg.defaultString) as string} className="team-img img-fluid mx-auto d-block" src="assets/img/nft/pose_3.png"/>
                        </div>
                        <div className="col">
                            <img title={translate(ds.luffyNftPageNFTImg.stringId, ds.luffyNftPageNFTImg.defaultString) as string} className="team-img img-fluid mx-auto d-block" src="assets/img/nft/pose_4.png"/>
                        </div>
                        <div className="col">
                            <img title={translate(ds.luffyNftPageNFTImg.stringId, ds.luffyNftPageNFTImg.defaultString) as string} className="team-img img-fluid mx-auto d-block" src="assets/img/nft/pose_5.png"/>
                        </div>
                    </div>
              </div>

              <div className="">
                <div className="container">
                  <div className="row py-5">
                    <div className="col text-lg-start">
                      <div className="container">
                          <div className="row g-5 align-items-center">
                              <div className="col-lg-6 col-md-12 mb-2">
                                  <img title={translate(ds.luffyNftPageNFTImg.stringId, ds.luffyNftPageNFTImg.defaultString) as string} className="mission-img img-fluid mx-auto d-block" src="assets/img/nft/pose_9.png"/>
                              </div>
                              <div className="col-lg-6 col-md-12 mb-2">
                                  <h1 className="lh-1 mb-3">
                                  {translate(ds.luffyNftPageSec2Title.stringId, ds.luffyNftPageSec2Title.defaultString)}
                                  </h1>
                                  <h4 className="">
                                  {translate(ds.luffyNftPageSec2SubHeading.stringId, ds.luffyNftPageSec2SubHeading.defaultString)}
                                  </h4>
                                  <p>
                                  {translate(ds.luffyNftPageSec2Text.stringId, ds.luffyNftPageSec2Text.defaultString)}
                                  </p>
                                  <ul>
                                    <li>{translate(ds.luffyNftPageSec2TextB0.stringId, ds.luffyNftPageSec2TextB0.defaultString)}</li> 
                                    <li>{translate(ds.luffyNftPageSec2TextB1.stringId, ds.luffyNftPageSec2TextB1.defaultString)}</li>
                                    <li>{translate(ds.luffyNftPageSec2TextB2.stringId, ds.luffyNftPageSec2TextB2.defaultString)}</li>
                                    <li>{translate(ds.luffyNftPageSec2TextB3.stringId, ds.luffyNftPageSec2TextB3.defaultString)}</li>
                                    <li>{translate(ds.luffyNftPageSec2TextB4.stringId, ds.luffyNftPageSec2TextB4.defaultString)}</li>
                                    <li>{translate(ds.luffyNftPageSec2TextB5.stringId, ds.luffyNftPageSec2TextB5.defaultString)}</li>
                                    <li>{translate(ds.luffyNftPageSec2TextB6.stringId, ds.luffyNftPageSec2TextB6.defaultString)}</li>
                                    <li>{translate(ds.luffyNftPageSec2TextB7.stringId, ds.luffyNftPageSec2TextB7.defaultString)}</li>
                                  </ul>                                
                              </div>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="miaru-bg-light">
                <div className="container">
                  <div className="row py-5">
                      <div className="col text-lg-start">
                        <div className="container">
                            <div className="row g-5 align-items-center">
                                <div className="col-lg-6 col-md-12 mb-2">
                                    <h1 className="lh-1 mb-3">
                                      {translate(ds.luffyNftPageSec3Title.stringId, ds.luffyNftPageSec3Title.defaultString)}
                                    </h1>
                                    <h4 className="">
                                      {translate(ds.luffyNftPageSec3SubHeading.stringId, ds.luffyNftPageSec3SubHeading.defaultString)}
                                    </h4>
                                    <p>
                                    {translate(ds.luffyNftPageSec3Text.stringId, ds.luffyNftPageSec3Text.defaultString)}
                                    </p>
                                    <a href={Links.faq} className="link-primary">
                                      {translate(ds.luffyNftPageSec3LinkText.stringId, ds.luffyNftPageSec3LinkText.defaultString)}
                                    </a>
                                </div>
                              <div className="col-lg-6 col-md-12 mb-2">
                                  <img title={translate(ds.luffyNftPageNFTImg.stringId, ds.luffyNftPageNFTImg.defaultString) as string} className="team-img img-fluid mx-auto d-block" src="assets/img/nft/pose_8.png"/>
                              </div>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="">
                <div className="container">
                  <div className="row py-5 justify-content-center">
                      <div className="col-10 text-lg-start">
                        <div className="p-5 text-center miaru-bg rounded-3">
                        
                            <h1 className="text-white">{translate(ds.luffyNftPageActionHeading.stringId, ds.luffyNftPageActionHeading.defaultString)}</h1>
                            <p className="col-lg-8 mx-auto fs-5 text-white">
                                {translate(ds.luffyNftPageActionSubHeading.stringId, ds.luffyNftPageActionSubHeading.defaultString)}
                            </p>
                            <div className="d-inline-flex gap-2 mb-5">
                                <a href={Links.nftSale} target="_blank" className="d-block mx-auto position-relative">
                                    <button className="d-inline-flex align-items-center btn btn-primary btn-lg px-4" type="button">
                                        {translate(ds.luffyNftPageActionLinkText.stringId, ds.luffyNftPageActionLinkText.defaultString)}
                                    </button>
                                </a>
                            </div>
                        </div>
                      </div>
                  </div>
                </div>
              </div>

          </div>
        </ErrorBoundary>
    );
};

export default LuffyNftPage;
